@import '~normalize.css';
@import "app/styles/mixin.less";
@import "~ngx-smart-modal/ngx-smart-modal";

html {
  max-width: 100vw;
  overflow-x: hidden;
  background: linear-gradient(180deg, #030303 0%, #121212 100%);
  background-size: contain;
  min-height: 100vh;

  &.sharedVideo {
    background: transparent;
    overflow-y: hidden;
  }
}

body {
  max-width: 100vw;
  padding-bottom: 48px;
  padding-top: 98px;
}

a,
a:visited {
  text-decoration: none;
}

img {
  display: block;
  height: auto;
  object-fit: cover;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

.page-content {
  max-width: 1604px;
  margin: 0 auto;
  padding: 0 48px;
  box-sizing: content-box !important;
}

.text-primary {
  font-family: @Source;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.08em;
  color: #FFFFFF;

  &.twoLines,
  &.threeLines {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  &.twoLines {
    -webkit-line-clamp: 2;
  }

  &.threeLines {
    -webkit-line-clamp: 3;
  }
}

.title-primary {
  font-family: @Montserrat;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.subtitle-primary {
  font-family: @Source;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.bold {
  font-weight: 600;
}

.standard-sectionTitle {
  font-family: @Montserrat;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.09em;
  color: #FFFFFF;
  position: relative;
  display: table;

  &::after {
    content: '';
    position: absolute;
    left: 0px;
    width: calc(100% - 1px);
    top: calc(100% + 6px);
    height: 6px;
    background: @primaryRed;
    border-radius: 2px;
  }
}

.standard-smallSectiontitle {
  .standard-sectionTitle;
  font-size: 18px;
  line-height: 23px;
  padding-top: 6px;
  padding-bottom: 6px;

  .highlighted {
    &::before {
      top: -6px;
      height: calc(100% + 12px);
      width: calc(100% + 3px);
    }
  }
}

.standard-button {
  font-family: @Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 147.2%;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  padding: 6px 15px;
  background: @primaryRed;
  border-radius: 4px;
  transition: 0.24s ease background;
  cursor: pointer;
  display: block;
  text-align: center;
  border: 2px solid @primaryRed;
  box-sizing: border-box;
  transition: background 0.18s ease-in;

  .linkIcon-play {
    width: 13px;
    height: auto !important;
  }

  &:hover {
    background: @red400;
  }

  &.deselected {
    background: transparent;
  }
}


.job-longText,
.news-article {
  * {
    color: white;
    font-family: @Source;
    letter-spacing: 0.05em;
  }

  span {
    font-size: 16px;
  }

  a {
    color: #34FABF;
  }

  h1,
  h2,
  h3,
  h4 {
    color: white;
    font-weight: 600;
  }
}

.news-article {
  img {
    width: auto !important;
    margin: 0 auto;
  }

  b {
    font-weight: 800;
  }
}

.newsContent {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.player-wrp .text-primary p,
.offlineStream-wrp .text-primary p,
.employee-wrp .text-primary p {
  margin-top: 0;
  margin-bottom: 0;
}

.nsm-dialog {
  top: 50% !important;
  transform: translateY(-50%) !important;

  .nsm-content {
    background: #101010;
    border: 1px solid #262626;
    padding: 24px;
    padding-top: 44px;

    svg {
      color: white;
    }
  }
}

@media screen and (max-width: 1024px) {
  body {
    padding-top: 78px;
  }

  .page-content {
    padding: 0 24px;
  }
}


@media screen and (max-width: 599px) {

  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .page-content {
    padding: 0 14px;
  }

  .standard-button {
    font-size: 14px;
  }

  .standard-sectionTitle {
    font-size: 21px;
  }

  .text-primary {
    font-size: 14px;
    line-height: 18px;
  }

  .title-primary {
    font-size: 21px;
    line-height: 25px;
  }

  .subtitle-primary {
    font-size: 16px;
    line-height: 18px;
  }
}

html,
body {
  height: auto;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}